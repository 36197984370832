import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const ArticlePageDivider = makeShortcode("ArticlePageDivider");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const DoDontComponent = makeShortcode("DoDontComponent");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const Link = makeShortcode("Link");
const CaptionTitle = makeShortcode("CaptionTitle");
const ImageCaption = makeShortcode("ImageCaption");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Foundations</Heading>
      <Text sx={{
        fontFamily: "-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif"
      }} mdxType="Text">
  Our voice reflects our values, while the tone adapts to the situation and
  ensures we convey the right message. When in doubt, always choose
  accessibility.
      </Text>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">How our voice sounds</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    We're inclusive, relatable, and joyful without being overly bubbly.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    We prioritize openness, accessibility, and conciseness.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    We focus on the benefit—how we improve users' lives through what we build.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    We sound human, innovative, and confident.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    We know our audience and speak from a place of expertise, but never as a
    know-it-all.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme={"brand"} mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    Build trust by being clear and confident: "Turn on cookies in your browser."
  </Do>
  <Dont variant={`blockquote`} mdxType="Dont">
    Don't be boastful or condescending: "Looks like you don't like our cookies."
  </Dont>
      </DoDontComponent>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Finding the right tone</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Focus on the users. Think about what they need and the challenges they face.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Be mindful of everything happening on the current, preceding, and following
    screens.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Look through Dovetail and reports to pick the best words.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Write as if you're talking directly to someone, keeping Appfire's values in
    mind.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Be helpful. Explain why certain options aren't available or what to do
    instead.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid stating the obvious about how to use the interface.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    A helpful message says why an interface element is not clickable: "There are
    no tasks to be exported yet."
  </Do>
  <Dont variant={`blockquote`} mdxType="Dont">
    Don't explain the obvious, like "click the link below" or "click Save to
    save changes."
  </Dont>
      </DoDontComponent>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Use plain language</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Keep the writing jargon-free, clear, and concise. People scan, not read.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Make active voice your default choice to improve readability.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use passive voice only when justified, such as to avoid blaming users for
    errors.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Stick to a simple tense and construction. Say, "You worked on more projects
    this year," instead of "We noticed you were working on more projects this
    year."
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Remove redundancies and lengthy introductions ("In today's world...", "We'd
    like to invite you to try a new feature our team has been working on...").
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    <strong>Active</strong> "This field requires mapping" is more direct, while
    passive "Tasks couldn't be added" helps maintain a neutral tone.
  </Do>
  <Dont variant={`blockquote`} mdxType="Dont">
    <strong>Passive</strong> "Mapping is required for this field" is less
    user-friendly, while active "You couldn't add tasks" blames the user.
  </Dont>
      </DoDontComponent>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist docsTheme="brand" mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Straight to the point</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Reserve the word <i>please</i> for requests that require extra effort.
    Overuse of <i>please</i> seems insincere and reduces our credibility.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid "Are you sure...?" which can create self-doubt and lower trust in the
    product.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't start with "Do you want to…?" either. It's redundant.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    "Delete this task?" is unambiguous, succinct, and precise.
  </Do>
  <Dont variant={`blockquote`} mdxType="Dont">
    "Are you sure you want to delete this task?" adds complexity.
  </Dont>
      </DoDontComponent>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Include everyone</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When referring to a person of unknown gender, use <i>they</i> instead of{" "}
    <i>he</i> or <i>she</i>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Minimize acronyms. If you need them, define them first.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid idioms and slang to make sure everyone understands your message.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Use emojis sparingly. They add noise and have different meanings in some
    countries.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Create descriptive links</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Keep your links concise but descriptive enough to know where they lead to.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Encourage users to click by saying "Learn more about pricing" instead of a
    generic "Learn more." People using screen readers will benefit as well.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    Link naturally within your text: <br /> "Visit the{" "}
    <Link href={`https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#accessibility_concerns`} mdxType="Link">
      MDN guidelines
    </Link>{" "}
    for guidance on accessible hyperlinks."
  </Do>
  <Dont variant={`blockquote`} mdxType="Dont">
    Don't create links such as "click here," "here," or "link" as they are not
    screen-reader friendly.
  </Dont>
      </DoDontComponent>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Be mindful of placeholders</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Placeholders are temporary text often used in forms, input fields, and
    dropdown menus.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Placeholders can be helpful at times but reduce form and input field
    accessibility.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    For forms and input fields, always use labels and don't include placeholders
    by default.<br></br>
    <i>Exception: we don't need labels for search fields.</i>
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    If a placeholder can add value to a specific context, ensure it doesn't
    repeat the label and is applied consistently across the form or screen.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    For select dropdown menus, use placeholders by default (Select team, Select
    city, etc.).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Skip articles (<i>a</i>, <i>an</i>, <i>the</i>) for those short prompts to
    improve scanning.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/user-attachments/assets/2b318b24-1069-4ab8-8d8f-f322610bd562" alt="An example of an empty input field." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      People are more likely to notice and fill out empty input fields.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <img src="https://github.com/user-attachments/assets/e2f80a1c-716e-439e-be8c-e5771a4f856c" alt="An example of an input field with a placeholder that repeats the label." />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Placeholders that repeat labels{" "}
      <Link href={`https://www.nngroup.com/articles/form-design-placeholders/#:~:text=5.%20Fields%20with%20stuff%20in%20them%20are%20less%20noticeable.`} mdxType="Link">
        can be overlooked
      </Link>{" "}
      and don't add value.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Bold and italics</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    You can use bold or italics to highlight a word or two.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid overuse, as nothing will stand out then.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't create blocks of italics. They're hard to scan, especially for people
    with dyslexia.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Screen readers skip bold or italics. Make sure everyone is aware of critical
    details.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <p>{`Do you have feedback for us? Share it on the `}<a parentName="p" {...{
          "href": "https://appfireworkspace.slack.com/archives/C03CTJZ4BUH"
        }}>{`design systems channel`}</a>{`.`}</p>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      